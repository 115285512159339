import { MetaFunction } from "@remix-run/react";
import { organizationSchema } from "~/constants/meta";
import { Hero } from "~/routes/_index/Hero";
import { Team } from "~/routes/_index/Team";
import { Vibes } from "~/routes/_index/Vibes";
import { Kontakt } from "~/routes/_index/Kontakt";
import { Region } from "~/routes/_index/Region";
import { Shop } from "~/routes/_index/Shop";
import { Drinks } from "~/routes/_index/Drinks";
import { ImageShow } from "~/routes/_index/ImageShow";

export const meta: MetaFunction = () => {
  return [
    organizationSchema,
    {
      tagName: "link",
      rel: "canonical",
      href: "https://updrinks.de/",
    },
    {
      title: "Voll Weinschorle – Erfrischend, spritzig und aus der Dose",
    },
    {
      name: "description",
      content:
        "Voll Weinschorle - Erlebe ausgewählten deutschen Wein und prickelndes Mineralwasser als perfektes Zusammenspiel. Gemacht für besondere und gemeinsame Momente.",
    },
    {
      property: "og:title",
      content:
        "🍇 Voll Weinschorle – Erfrischend, spritzig und aus der Dose 💦",
    },
    {
      property: "og:description",
      content:
        "Erfrischend, spritzig und perfekt abgestimmt: Voll Weinschorle verbindet ausgewählten deutschen Wein mit prickelndem Mineralwasser. Ideal für besondere und gemeinsame Momente.",
    },
    {
      property: "og:image",
      content: "https://updrinks.de/ogimage.png",
    },
    {
      property: "og:image:alt",
      content: "Voll Weinschorle – Erfrischend, spritzig und aus der Dose",
    },
    {
      property: "og:url",
      content: "https://updrinks.de/",
    },
    {
      property: "og:type",
      content: "website",
    },
    {
      property: "og:locale",
      content: "de_DE",
    },
    {
      name: "twitter:card",
      content: "summary_large_image",
    },
    {
      name: "twitter:title",
      content:
        "🍇 Voll Weinschorle – Erfrischend, spritzig und aus der Dose 💦",
    },
    {
      name: "twitter:description",
      content:
        "Erfrischend, spritzig und perfekt abgestimmt: Voll Weinschorle verbindet ausgewählten deutschen Wein mit prickelndem Mineralwasser. Ideal für besondere und gemeinsame Momente.",
    },
    {
      name: "twitter:image",
      content: "https://updrinks.de/ogimage.png",
    },
    {
      name: "twitter:image:alt",
      content: "Voll Weinschorle – Erfrischend, spritzig und aus der Dose",
    },
    {
      name: "robots",
      content: "index, follow",
    },
  ];
};

export default function Index() {
  return (
    <div className="overflow-x-clip">
      <Hero />
      <Vibes />
      <Drinks />
      <Region />
      <Team />
      {/*<ImageShow />*/}
      <Shop />
      <Kontakt />
    </div>
  );
}
